import { testimonials } from "/js/testimonials";
import { squares } from "/js/squares";
var ScrollMagic = require('scrollmagic');

// testimonials
var testimonialsInstance = new testimonials;
testimonialsInstance.setSliderHolderWidth();
// var testimonialsAutoSlide = window.setInterval(function(){
//   testimonialsInstance.slidetestimonials(3010);
// }, 5000);
// object.addEventListener("touchmove", () => {
//   console.log('Touchiiii!');
// });

// Squares
var squaresInstance = new squares;
squaresInstance.colorSquares();
let fadeInterval = window.setInterval(function () { squaresInstance.squareFade(squares) }, 1000);

// Window Resize
var windowWidth = window.innerWidth;
window.onresize = function () {
  // testimonials
  testimonialsInstance.setSliderHolderWidth();

  // squares
  if (window.innerWidth != windowWidth) {
    windowWidth = window.innerWidth;
    window.clearTimeout(fadeInterval);
    squaresInstance.colorSquares();
    fadeInterval = window.setInterval(function () { squaresInstance.squareFade(squaresInstance.squares) }, 1000);
  }
};



var controller = new ScrollMagic.Controller();

const HeaderScene = new ScrollMagic.Scene({
  duration: 2300, // the scene should last for a scroll distance of 100px
  offset: 0 // start this scene after scrolling for 50px
});
HeaderScene
  .setPin('#head') // pins the element for the the scene's duration
  .addTo(controller) // assign the scene to the controller



const H1Scene = new ScrollMagic.Scene({
  duration: 300, // the scene should last for a scroll distance of 100px
  offset: 0 // start this scene after scrolling for 50px
});
H1Scene
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".firstscreen", "visible");

const DaumenScene = new ScrollMagic.Scene({
  duration: 300, // the scene should last for a scroll distance of 100px
  offset: 0 // start this scene after scrolling for 50px
});
DaumenScene
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".daumenfrau", "visible");

const QuestionScene = new ScrollMagic.Scene({
  duration: 1300, // the scene should last for a scroll distance of 500px
  offset: 500 // start this scene after scrolling for 800px
});
QuestionScene
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".fragen", "visible");

const FrauScene = new ScrollMagic.Scene({
  duration: 1300, // the scene should last for a scroll distance of 100px
  offset: 500 // start this scene after scrolling for 50px
});
FrauScene
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".frau", "visible");

const questionScenes = [];
for (let i = 0; i < 4; i++) {
  questionScenes[i] = new ScrollMagic.Scene({
    duration: 10000, // the scene should last for a scroll distance of 500px
    offset: 600 + (i * 300) // start this scene after scrolling for 800px
  });
  questionScenes[i]
    .addTo(controller)
    .setClassToggle(".fragen li:nth-child(" + (i + 1) + ")", "visible");
}

const KennstDu = new ScrollMagic.Scene({
  duration: 8000, // the scene should last for a scroll distance of 500px
  offset: 1900 // start this scene after scrolling for 800px
});
KennstDu
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".kennstdu", "visible");



const Products = new ScrollMagic.Scene({
  duration: 100000, // the scene should last for a scroll distance of 500px
  offset: 3200 // start this scene after scrolling for 800px
});
Products
  .addTo(controller) // assign the scene to the controller
  .setClassToggle(".products", "visible");