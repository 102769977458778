class squares{

  constructor() {
    this.squaresize = 50;
    this.colors = ['#ff6d00', '#048174', '#f0dc00', '#c7d300', '#b9e0de', '#5c004e'];
    this.squares = this.numberOfSquares(this.squaresize);
  }

  shuffle(a) {
    var j, x, i;
    for (i = a.length - 1; i > 0; i--) {
        j = Math.floor(Math.random() * (i + 1));
        x = a[i];
        a[i] = a[j];
        a[j] = x;
    }
    return a;
  }

  randomBetween(x, y) {
    return Math.floor(Math.random() * y) + x
  }

  colorOfSquare(lastColor) {
    var colors = ['orange', 'petrol', 'gelb', 'gruen', 'blau', 'violett'];
    var color = colors[this.randomBetween(0, 6)];
    while(color == lastColor){
      var color = colors[this.randomBetween(0, 6)];
    }
    return color;
  }

  colorSquares() {
    squares = this.numberOfSquares(this.squaresize);


    if(document.getElementsByClassName('colorSquare').length > 0){
      document.getElementById("kacheln").innerHTML='';
    }

    var xy = 50;
    var windowWidth = window.innerWidth;
    if(window.innerWidth <= 540){
      xy = 50;
      windowWidth=window.innerWidth;
    }

    var x=0;
    var y=0;

    var colors = ['orange', 'petrol', 'gelb', 'gruen', 'blau', 'violett'];
    var lastColor = false;
    var color = 0;
    var fadeOut = '';
    var delays = this.shuffle([2.1, 2.2, 2.3, 2.4, 2.5, 2.6, 2.7, 2.8, 2.9, 3.0, 3.1, 3.2]);
    var delayIndex = -1;

    for(var i = 0; i < squares; i++) {
      color = this.colorOfSquare(lastColor);

      fadeOut='nofade';
      const fadeTextX = (xy * 6);
      const fadeTextY = (xy * 2);
      if(x< fadeTextX && y < fadeTextY) {
        fadeOut = 'fadeOut';
        delayIndex = delayIndex + 1;
      }

      const node = document.createElement("div");
      node.classList.add('colorSquare');
      node.classList.add(color);
      node.classList.add(fadeOut);
      node.style.left = x + 'px';
      node.style.top = y + 'px';
      node.style.animationDelay = delays[delayIndex] + 's';


      document.getElementById("kacheln").appendChild(node);

      lastColor = color;
      x=x+xy;
      if(x > windowWidth){
        x=0;
        y=y+xy;
      }

      const that = this;
    }
  }

  squareFade() {
    const squares = this.squares;
    var pickSquare = this.randomBetween(0, squares);
    var color = this.randomBetween(0, 6);
    document.getElementsByClassName('colorSquare')[pickSquare].style.backgroundColor = this.colors[color];
  }

  numberOfSquares(size){
    const squarenumber = window.innerWidth/size*2;
    return parseInt(squarenumber)+2;
  }

}

export {squares};
