class testimonials{

  constructor() {
    this.testimonialSlideHolder = document.getElementById('testimonialSlideHolder');
    this.testimonialsHolder = document.getElementsByClassName('testimonials')[0];
    this.testimonials = this.testimonialsHolder.getElementsByClassName('testimonial');
    this.testimonialwidth = 33;
    this.breakponttestimonials = 770;
    this.autoSlide = true;
    this.sliding = false;
    this.touchstartPoint = false;
    const that = this;

    if(this.autoSlide == true){
      that.autoSlideInterval = window.setInterval(function(){
        that.slidetestimonials(2000, -1);
      }, 5000);
    }

    testimonialSlideHolder.addEventListener("touchmove", (e) => {

      if(that.autoSlide){
        clearInterval(that.autoSlideInterval);
        that.autoslide = false;
      }

      if(this.touchstartPoint == false){
        this.touchstartPoint = parseInt(e.changedTouches[0].clientX);
      }
      else{
        this.testimonialsHolder.style['transitionTimingFunction'] = 'linear';
        if(e.changedTouches[0].clientX < this.touchstartPoint){
          if(this.sliding == false){
            that.slidetestimonials(2000, -1);
          }
        }
        else{
          if(this.sliding == false){
            this.pushToLeft();
          }
        }
      }

    });


  }

  setTestmonialWidth(){
    const windowWidth = window.innerWidth;
    if(windowWidth <= this.breakponttestimonials){
      this.testimonialwidth = 100;
    }
    else{
      this.testimonialwidth = 33;
    }
  }


  // Breite des Slider-Holders ermitteln und setzen
  setSliderHolderWidth(){
    const windowWidth = window.innerWidth;
    this.setTestmonialWidth();
    var plus;
    if(windowWidth <= this.breakponttestimonials){
      plus = '200px';
    }
    else{
      plus = '60px';
    }
    const holderWidth = 'calc(' + (this.testimonials.length * this.testimonialwidth) + '% + ' + plus + ')'; // 60
    this.testimonialsHolder.style.width = holderWidth;
  }

  pushToRight(){
    const windowWidth = window.innerWidth;
    this.testimonialsHolder.style['transition'] = 'none';
    var plus;
    if(windowWidth <= this.breakponttestimonials){
      plus = '-20px';
    }
    else{
        plus = '-20px';
    }
    this.testimonialsHolder.style['marginLeft'] = plus;
    this.testimonialsHolder.appendChild(this.testimonialsHolder.getElementsByClassName('testimonial')[0]);
    this.sliding = false;
    this.touchstartPoint= false
  }

  pushToLeft(){
    this.sliding = true;
    this.setTestmonialWidth();
    // nach versetzen

    const windowWidth = window.innerWidth;
    this.testimonialsHolder.style['transitionDuration'] = '0s';
    var plus;
    if(windowWidth <= this.breakponttestimonials){
      plus = '60px';
    }
    else{
        plus = '32px';
    }
    // this.testimonialsHolder.style['marginLeft'] = plus;
    this.testimonialsHolder.prepend(this.testimonialsHolder.getElementsByClassName('testimonial')[this.testimonialsHolder.getElementsByClassName('testimonial').length - 1]);
    this.testimonialsHolder.style.marginLeft = 'calc(-' + this.testimonialwidth + '% - ' + plus + ')';
    // this.testimonialsHolder.style.marginLeft = '-' + this.testimonialwidth + '%';
    console.log('-' + this.testimonialwidth + '%');
    var that = this;
    window.setTimeout(function(){
      that.slidetestimonials(2000, 1);
    },
    1);
  }

  slidetestimonials(pause, direction){
    this.sliding = true;
    const windowWidth = window.innerWidth;
    let plus;
    if(windowWidth <= this.breakponttestimonials){
      plus = '60px';
    }
    else{
      plus = '32px';
    }
    const that = this;
    if(direction == -1){
      this.testimonialsHolder.style.marginLeft = 'calc(-' + this.testimonialwidth + '% - ' + plus + ')';
      this.testimonialsHolder.style['transition'] = 'margin-left 2s';
      if(this.touchstartPoint == false){
        this.testimonialsHolder.style['transition'] = 'margin-left 2s';
      }
      else{
        this.testimonialsHolder.style['transition'] = 'margin-left 0.5s';
        pause = 500;
      }
      window.setTimeout(function(){
        that.pushToRight();
      },
      pause);
    }
    else{
      // this.testimonialsHolder.style['transitionDuration'] = '0.5s';
      this.testimonialsHolder.style['transition'] = 'margin-left 0.5s';
      // this.testimonialsHolder.style['transitionTimingFunction'] = 'linear';
      this.testimonialsHolder.style.marginLeft = 'calc(0% - 20px)';
      window.setTimeout(function(){
        that.sliding = false;
        that.touchstartPoint = false
      },
      500);
    }
  }


}

export {testimonials};
